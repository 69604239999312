/*================================================================================
	Item Name: Apex - Angular 4 Bootstrap Admin Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.thumb_img img {
	width: 100%;
    height: 200px;
    object-fit: cover;
}
.btn_add_adrs {
	width: 100%;
    height: 100%;
	min-height: 329px;
    display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px #e0e0e0 dashed;
    border-radius: 0.35em;
    color: #e0e0e0 !important;
    font-size: 20px;
	transition: all 0.3s ease;
}
/* .btn_add_adrs:hover {
	border-color: #975AFF;
} */
.btn_add_adrs span {
	display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 0.15em;
    width: 48px;
    height: 48px;
    color: #fff;
    margin-bottom: 10px;
	transition: all 0.3s ease;
}
/* .btn_add_adrs:hover span {
	background-color: #975AFF;
} */
/* .address_card .card-body:hover {
	border-color: #975AFF !important;
	transition: all 0.3s ease;
} */
/* .shopping-cart .card-content:hover {
	border-color: #975aff !important;
    transition: all 0.3s ease;
} */
/* .total_amt {

} */
.total_amt li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style: none;
	border-bottom: 1px solid #e0e0e0;
	padding: 10px 0;
}
.total_amt li:last-child {
	border-bottom: none;
	font-weight: 600;
}
.total_amt li span {
	flex-basis: 25%;
	font-weight: 600;
	text-align: right;
}
#fabs-examples .card-img-top {
	width: 100%;
	height: 270px;
	object-fit: contain;
	border-bottom: 1px solid #e0e0e0;
}
.btn-shadow:focus {
	box-shadow: 0 0 0 0.3rem rgb(242 34 34 / 25%) !important;
}
.single-product-spec {
	max-width: 50%;
	padding: 0;
}
.single-product-spec li {
	display: flex;
	align-items: center;
	padding: 0 0 10px;
	list-style: none;
}
.single-product-spec li span {
	flex-basis: 30%;
	font-weight: 600;
}


.dropdown .dropdown-menu .dropdown-item:active, .dropup .dropdown-menu .dropdown-item:active, .dropright .dropdown-menu .dropdown-item:active, .dropleft .dropdown-menu .dropdown-item:active {
    background-color: rgb(233 236 239) !important;
	color: rgba(0, 0, 0, 1);
}